<div class="w-full flex flex-col justify-center items-center p-4">
	@if (!onlyText()) {
		<p class="py-5 text-6xl">
			<mat-icon inline>{{ icon() }}</mat-icon>
		</p>
	}
	<p class="text-2xl text-layout-stone-gray">
		<ng-content></ng-content>
	</p>
	<p class="text-lg text-layout-shingle-gray">
		<ng-content select="[empty-state-message]"></ng-content>
	</p>
	<div class="pt-5">
		<ng-content select="[empty-state-actions]"></ng-content>
	</div>
</div>
